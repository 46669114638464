* {
  margin: 0px;
  padding: 0px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
}

h1 {
  font-family: "Pacifico", cursive;
}
