.container {
  padding-top: 4rem;
}

.count {
  padding: 0 2rem 0 0;
  display: inline-block;
  margin: 0rem;
  align-items: center;
}

.heading {
  text-align: center;
}
