#container-second.active {
  padding-left: 24%;
}

.container-second {
  transition: 0.4s;
  padding-left: 6%;
}

.items {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.item {
  background: #f1f1f1;
  margin-top: 15px;
  margin-right: 10px;
}

.item-header {
  color: white;
  position: relative;
  margin-bottom: 20px;
}

.price {
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.title {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 80%;
  max-width: 360px;
  text-align: center;
  padding: 8px 0;
}

.price span {
  font-size: 30px;
}

.item-list {
  padding: 10px 20px;
}

.item-sidebar a,
.item a {
  display: block;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  padding: 8px 0;
  font-size: 14px;
  border: 1px solid;
  max-width: 360px;
}

.border {
  width: 80px;
  height: 1px;
  background: #ddd;
  margin: auto;
}

.item-list li {
  margin: 8px;
  font-size: 17px;
  text-align: center;
}

.item .item-header {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.item .title {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}

.item a {
  color: #00d2d3;
  transition: 0.5s;
  text-decoration: none;
}

.item a:hover {
  border-radius: 35px;
  background: #00d2d3;
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .container-second {
    padding-top: 10%;
    padding-left: 12%;
  }
}
