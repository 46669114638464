#sidebar {
  top: 3rem;
  position: fixed;
  min-width: auto;
  width: 19%;
  height: 100%;
  background: #30303c;
  left: -19%;
  transition: 0.4s;
}

#sidebar ul li {
  list-style: none;
  color: #fff;
  font-size: 20px;
  padding-right: 14px;
}

.toggle-btn {
  width: 36px;
  position: relative;
  top: 2rem;
  left: 110%;
}

.toggle-btn span {
  width: 30px;
  height: 4px;
  background: #000;
  display: block;
  margin-top: 4px;
}

.toggle-btn:hover {
  cursor: pointer;
}

ul.listy {
  padding-top: 2.5rem;
}

li.first {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: white;
}

li.listItem {
  padding: 13px 0px 13px 10%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: white;
}

li.listItem:hover {
  background-color: #008db7;
  cursor: pointer;
}

a.linky {
  display: block;
  color: white;
  text-decoration: none;
}

a.linky:hover {
  color: white;
  text-decoration: none;
}

#sidebar.active {
  left: 0;
}

@media screen and (max-width: 760px) {
  #sidebar.active {
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.3s;
  }
  #listy {
    visibility: hidden;
  }

  #listy.active {
    visibility: visible;
  }
}
